import React from "react";
import { graphql } from "gatsby";
import { Link } from "react-scroll";
import DefaultLayout from "components/layout/default";
import { CyberHygieneProduct, CyberHygieneLogo} from "components";
import HubspotForm from "react-hubspot-form";
import Img from "components/image";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import whiteTopRadius from "images/product-radius-top-cap.svg";
import whiteBottomRadius from "images/product-radius-bottom-cap.svg";
import grayRadiusTop from "images/radius-cap-gray-top-sm.svg";
import grayRadiusBottom from "images/radius-cap-gray-bottom-sm.svg";
import blackRadiusTop from "images/radius-cap-black-top-sm.svg";
import blackRadiusBottom from "images/radius-cap-black-bottom-sm.svg";
import image1 from "images/mas-cyber-security/image-1.png";
import image2 from "images/mas-cyber-security/image-2.png";
import image3 from "images/mas-cyber-security/image-3.png";
import image4 from "images/mas-cyber-security/image-4.png";
import image5 from "images/mas-cyber-security/image-5.png";
import bukalapak from "images/logos/bukalapak.jpg";
import detrack from "images/logos/detrack.png";
import moneysmart from "images/logos/moneysmart.svg";
import gnowbe from "images/logos/gnowbe.png";
import tiket from "images/logos/tiket.com.svg";
import ninjavan from "images/logos/ninjavan.svg";
import fundingLogo from "images/logos/funding-societies.png";
import jirnexu from "images/logos/Jirnexu.png";

import complianceImage from "images/compliance/compliance-MainLogo.png";
import wardenImage from "images/product-warden.svg";
import vcisoLogo from "images/ciso/vCISO-MainLogo.png";

import clientLogo1 from "images/client-propertyguru.svg";
import clientLogo2 from "images/client-tiket.com.svg";
import clientLogo3 from "images/client-gojek.svg";
import clientLogo4 from "images/client-moneysmart.svg";
import style from "styles/pages/about.module.css";
import fundingSociety from "images/logos/funding-society-logo.png";
import quotes from "images/orange-quotes.png";

const HUBSPOT_FORM_ID = "d5e309a0-a247-4a4f-990c-c03bdfdcd6b6";

const summary = [
  {
    number: "01",
    text: "Implement adequate administrative account access policies"
  },
  {
    number: "02",
    text:
      "Apply security patches and relevant security controls in a timely manner to address vulnerabilities"
  },
  {
    number: "03",
    text:
      "Ensure compliance of security standards for every system, implementing relevant controls when necessary"
  },
  {
    number: "04",
    text:
      "Implement controls at the network perimeter to restrict all unauthorised network traffic"
  },
  {
    number: "05",
    text:
      "Implement malware protection measures on every system to mitigate the risk of malware infection, where available and can be implemented"
  },
  {
    number: "06",
    text:
      "Implement multi-factor authentication for all administrative and critical accounts"
  }
];

const SummaryCard = items => {
  return items.map((item, index) => {
    const mt = index === 0 ? "-mt-2" : "mt-0";
    return (
      <div key={index} className={`flex flex-row pt-3 mb-5`}>
        <div className={`w-1/3 text-2xl sm:text-4xl`}>
          <h1 className={`${mt}`}>{item.number}</h1>
        </div>
        <div className="w-2/3">
          <p className="text-xs sm:text-lg">{item.text}</p>
        </div>
      </div>
    );
  });
};

function MasCyberHygiene(props) {
  const [hubspotFormReady, setHubspotFormReady] = React.useState(false);
  const [hubspotFormSubmitted, setHubspotFormSubmitted] = React.useState(false);

  function handleHubspotFormReady(event) {
    setHubspotFormReady(true);
  }

  function handleHubspotFormSubmit(event) {
    if (event.data.type === "hsFormCallback") {
      if (event.data.eventName === "onFormSubmit") {
        setHubspotFormSubmitted(true);
      }
    }
  }

  function renderContactFormHint() {
    let hint;
    const privacyLink = (
      <a
        href="/legal/privacy"
        className="underline text-orange"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Notice
      </a>
    );
    if (hubspotFormReady === true && hubspotFormSubmitted === false) {
      hint = (
        <p className="text-black text-sm my-4">
          By clicking “Send”, you acknowledge that you have read, and consent to
          collection, use and disclosure of your personal data for the purposes
          set out in our {privacyLink}.
        </p>
      );
    }
    return hint;
  }

  return (
    <DefaultLayout
      location={props.location}
      title={"Horangi - MAS Cyber Hygiene Compliance"}
      description={
        "Fast track compliance with the MAS Cyber Hygiene Notice on the Horangi Platform."
      }
    >
      <div className="w-full">
        <section className="h-screen flex justify-center items-center">
          <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-center w-2/3 xl:max-w-4xl">
            Fast Track Compliance With The MAS Cyber Hygiene Notice on the
            Horangi Platform
          </h1>
          <Img
            {...IMAGE_BACKGROUND_PROPS}
            objectFit="contain"
            alt="Background"
            fluid={props.data.backgroundImage.childImageSharp.fluid}
          />
        </section>
        <section id="white-radius-top" className="w-full">
          <img
            src={whiteTopRadius}
            alt=""
            className={`relative  -mt-40 w-full`}
          />
        </section>
        <section
          id="1-section"
          className="flex justify-center bg-white"
          style={{ marginTop: -1 }}
        >
          <div className=" flex flex-col md:flex-row text-black xxl:-mt-20 relative">
            <div className="p-5 max-w-2xl w-full md:w-1/2 sm:pl-20">
              <p className="text-sm xxl:text-lg text-center md:text-left p-10 sm:p-0">
                As FinTech organizations move to comply with Singapore laws and
                mitigate the largest cyber risks today, it can be unclear how
                exactly they can meet MAS’ requirements amid the shortage of
                cybersecurity talent. With a mix of leading cybersecurity
                consultants and proprietary RegTech, Horangi is poised to help
                FinTech organizations start a timely and cost-effective Cyber
                Hygiene compliance program.
              </p>
              <Link
                activeClass="active"
                to="summary"
                spy={true}
                smooth={true}
                offset={100}
                duration={800}
              >
                <div
                  id="orange-btn"
                  style={{ maxWidth: 250 }}
                  className={`btn btn-orange w-3/5 text-center mt-0 sm:mt-10 mx-auto md:mx-0`}
                >
                  About Cyber Hygiene
                </div>
              </Link>
            </div>

            <div className="w-1/2 relative mx-auto">
              <img
                style={{ maxHeight: 500 }}
                src={image1}
                alt=""
                className="mt-10 sm:-mt-0 md:-mt-20"
              />
            </div>
          </div>
        </section>
        <section
          id="summary"
          style={{ marginTop: -1 }}
          className="flex justify-center items-center bg-white text-black pt-48 sm:pt-96 flex-col"
        >
          <h1 className="text-lg font-bold text-center uppercase max-w-xs sm:max-w-md xxl:max-w-xl">
            Summary of the Notice on Cyber Hygiene that takes effect from 6 Aug
            2020
          </h1>
          <div
            id="summary-card"
            className="w-4/5 max-w-lg lg:max-w-2xl rounded overflow-hidden shadow-lg mt-10 p-10 pt-10"
          >
            {SummaryCard(summary)}
          </div>
          <div
            id="societies-card"
            className="w-4/5 md:w-full max-w-2xl rounded overflow-hidden shadow-lg mt-48 sm:mt-96 p-5 sm:p-20 sm:mb-20"
          >
            <img
              src={quotes}
              alt=""
              className="absolute -mt-16 sm:-mt-32 xxl:-mt-32 -ml-5 sm:-ml-20 h-16 sm:h-24"
            />
            <div className="flex flex-row mt-5 sm:mt-0">
              <img src={fundingSociety} alt="" style={{ height: 50 }} />
              <p className="text-xl sm:text-2xl mb-5 mt-2">
                <span className="font-bold text-gray-800">funding </span>
                societies
              </p>
            </div>
            <p className="italic text-sm sm:text-md p-5 sm: p-0">
              “Horangi’s in-depth knowledge of Singapore’s financial regulatory
              landscape, combined with the professionalism of its consultants,
              makes it always a pleasure to work with them. In Horangi’s hands,
              I can trust that my company is always well ahead of any regulatory
              change.”
            </p>
            <p className="sm:mt-5 sm:ml-5 text-gray-500 uppercase text-sm font-bold p-5 sm:p-0">
              Matt Lambie, GROUP CTO
            </p>
          </div>
        </section>
        <section
          id="logos"
          style={{ marginTop: -1 }}
          className="flex flex-col justify-center items-center bg-white text-black pt-48"
        >
          <h1 className="font-bold text-center text-sm sm:text-xl p-5 uppercase">
            Customers we’ve supported on the compliance journey
          </h1>
          <div className="flex flex-col sm:flex-row mt-2 sm:mt-5">
            <CyberHygieneLogo image={moneysmart} className="h-4 sm:h-8 sm:mt-4" />
            <CyberHygieneLogo image={jirnexu} className="h-5 sm:h-10" />
            <CyberHygieneLogo image={ninjavan} className="h-5 sm:h-10" />
            <CyberHygieneLogo image={fundingLogo} className="h-5 sm:h-10" />
          </div>
          <div className="flex flex-col sm:flex-row mt-3 sm:mt-10">
          <CyberHygieneLogo image={tiket} className="h-5 sm:h-10" />
          <CyberHygieneLogo image={bukalapak} className="h-5 sm:h-10" />
          <CyberHygieneLogo image={detrack} className="sm:-mt-0 h-3 sm:h-8" />
          <CyberHygieneLogo image={gnowbe} className="sm:-mt-0 h-5 sm:h-10" />
          </div>
        </section>
        <section
          id="text-section"
          style={{ marginTop: -1 }}
          className="flex flex-col justify-center items-center bg-white text-black pt-96 sm:pt-128 pl-5 pr-5 sm:pl-20 sm:pr-20 xxl:pr-0 xxl:pl-0"
        >
          <div
            id="block-1"
            className="flex flex-col lg:flex-row w-full xxl:w-3/5"
          >
            <div className="lg:w-2/5 xxl:w-1/2 -mt-20">
              <img src={image2} alt="" />
            </div>
            <div className="w-full lg:w-3/5 xxl:w-1/2 lg:pl-20">
              <h1 className="font-bold mb-5 sm:text-left mt-10 lg:mt-0">
                EXECUTIVE SUMMARY
              </h1>
              <p className="">
                On Aug 6 2019, the Monetary Authority of Singapore (MAS)
                released a Notice on Cyber Hygiene, containing six distinct
                sections, to all Financial Service Institutions (FSIs) in
                Singapore. The notice also extends to foreign firms that handle
                financial data of customers or FSIs based in Singapore.
              </p>
              <p className="mt-5 mb-5">
                FinTech organizations regulated by Singapore law require
                relevant MAS licenses to operate and provide services such as
                banking, insurance, and brokerage. However, they risk getting
                their MAS licenses revoked should they fail to meet the Cyber
                Hygiene Notice requirements by Aug 6 2020.
              </p>
              <p>
                Considering that a typical cybersecurity assessment and
                compliance program can take up to a year, FinTech organizations
                who have yet to begin addressing their compliance challenges
                have to start assessing their requirements immediately.
              </p>
            </div>
          </div>
          <div
            id="block-2"
            className="flex flex-col-reverse lg:flex-row w-full xxl:w-3/5 mt-64"
          >
            <div className="w-full lg:w-2/3">
              <h1 className="font-serif italic leading-tight whitespace-pre-wrap text-2xl sm:text-5xl mt-10 lg:mt-0 text-left">
                Should You Leverage Domain Experts To Assess Compliance Risk?
              </h1>
              <p className="mb-5 mt-5">
                Security and compliance risks vary between organizations of
                different sizes and scale, and the burden of risk assessment
                falls on the FSI. While some FinTech organizations may take it
                upon themselves to address compliance requirements, a lack of
                robust compliance reporting processes potentially makes it more
                challenging for FinTech organizations to prove and demonstrate
                compliance with the Cyber Hygiene Notice.
              </p>
              <p>
                Without focused cybersecurity expertise to both analyze and
                report on your Cyber Hygiene Notice compliance, FinTech
                organizations may find themselves investing an unnecessary
                amount of resources to demystify compliance requirements.
                Failure to accurately quantify compliance requirements could
                mean multiple iterations of compliance tasks for various
                departments.
              </p>
              <Link
                activeClass="active"
                to="contact-form"
                spy={true}
                smooth={true}
                offset={20}
                duration={800}
              >
                <div
                  id="orange-btn"
                  style={{ maxWidth: 250 }}
                  className={`btn btn-orange text-center mt-10 sm:mt-10 mx-auto md:mx-0 w-2/4 sm:w-1/4`}
                >
                  Contact Us
                </div>
              </Link>
            </div>
            <div className="flex flex-col mx-auto">
              <img
                className="-mt-20 sm:ml-20 sm:-mt-20 max-wd-sm sm:max-w-lg h-64 sm:h-auto"
                src={image3}
                alt=""
              />
            </div>
          </div>
        </section>
        <section
          id="grey-radius"
          className="bg-white text-black pt-64"
          style={{ marginTop: -1 }}
        >
          <img src={grayRadiusTop} alt="" className="w-full" />
          <div
            className="flex flex-col justify-center items-center"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <h1 className="font-serif italic leading-tight whitespace-pre-wrap text-center w-full sm:w-2/3 mb-10 sm:mb-0 text-2xl sm:text-4xl xxl:text-5xl mt-10 sm:mt-0 p-5 sm:p-0">
              But there is a more efficient approach to compliance that benefits
              FinTech organizations both in the short and long term
            </h1>
            <div className="flex flex-col-reverse lg:flex-row mt-10 w-full sm:w-2/3">
              <div className="w-full lg:w-1/2 mt-10 lg:mt-40 p-5 sm:p-0">
                <h1 className="font-bold text-sm text-left mb-5">
                  BANK ON A SECURITY-FIRST APPROACH TO STREAMLINE COMPLIANCE
                  REQUIREMENTS
                </h1>
                <p className="sm:p-0 text-left">
                  A strong security foundation can help FinTech organizations
                  easily demonstrate compliance to not only the Cyber Hygiene
                  Notice, but also to other relevant regulations and frameworks
                  in applicable countries. Because MAS depends on cybersecurity
                  thought leaders to determine the adequacy of security
                  standards, FinTech organizations can take advantage of a
                  security-first innovation mindset to consistently stay ahead
                  of regulations, minimizing disruption to their innovation.
                </p>
              </div>
              <div className="w-full lg:w-1/2 -mt-10 sm:mt-0">
                <img
                  src={image4}
                  alt=""
                  className="lg:ml-20 h-64 lg:h-auto mx-auto lg:mx-0"
                />
              </div>
            </div>
          </div>
          <img src={grayRadiusBottom} className="w-full" alt="" />
        </section>
        <section
          style={{ marginTop: -1 }}
          id="trusted-section"
          className="bg-white text-black flex flex-col justify-center items-center pt-48 sm:pt-128 pb-64 sm:pb-40"
        >
          <h1 className="text-md sm:text-2xl font-bold text-center w-4/5 sm:w-full">
            TRUSTED BY LEADING FINTECH ORGANIZATIONS
          </h1>
          <p className="w-full sm:w-1/2 text-center text-lg mt-5 mb-10 p-5 sm:p-0">
            Having helped customers like MoneySmart, Funding Societies,
            Coinhako, and Bondevalue on their compliance with the MAS Technology
            Risk Management Guidelines (MAS TRMG), Horangi’s SaaS security
            platform is uniquely positioned to help FinTech organizations of all
            sizes.
          </p>
          <img
            src={image5}
            alt=""
            style={{ maxWidth: 900 }}
            className="h-40 sm:h-auto"
          />
          <p className="mt-10 text-center w-full sm:w-1/2 p-5 sm:p-0 text-lg">
            Leveraging Horangi’s tailored combination of RegTech, cybersecurity
            services, and strong domain expertise in Singapore’s regulatory
            landscape, FinTech organizations can accelerate the Cyber Hygiene
            Notice compliance process.
          </p>
        </section>
        <section id="black-radius" className="bg-white -mt-1 text-white pb-48">
          <img src={blackRadiusTop} alt="" className="w-full -mb-1" />
          <h1 className="bg-black font-serif italic leading-tight whitespace-pre-wrap text-2xl sm:text-5xl xxl:text-6xl p-5 sm:p-0 text-center mt-1">
            A Compliance-Focused Solution Suite
          </h1>
          <div
            id="radius-body"
            className="bg-black flex flex-col justify-center items-center -mt-5 sm:-mt-1"
          >
            <div className="container mx-auto px-8 py-24 z-10 relative max-w-4xl">
              <CyberHygieneProduct
                title="Warden"
                description="A cloud monitoring tool used to identify, analyze and
                    protect your cloud infrastructure from vulnerabilities and
                    misconfigurations. Based on the CIS Benchmark, a recognized
                    security standard by MAS."
                url="/products/warden"
                image={wardenImage}
              />
              <CyberHygieneProduct
                title="Compliance"
                description="Demonstrate compliance with the world’s leading
                cybersecurity and privacy standards."
                url="/solutions/compliance"
                image={complianceImage}
                className="mt-20 mb-20"
              />
              <CyberHygieneProduct
                title="vCISO"
                description="Accelerate secure innovation when you outsource your
                security to Asia's leading cybersecurity team."
                url="/solutions/vciso"
                image={vcisoLogo}
              />
            </div>
          </div>
          <img src={blackRadiusBottom} alt="" className="w-full" style={{marginTop: -1}}/>
        </section>

        <section
          id="contact-form"
          className="relative flex flex-col justify-center items-center bg-white text-black -mt-1 mb-128 sm:mb-0"
        >
          <h1 className="font-bold text-md p-5 sm:p-0 text-center -mt-20 sm:mt-0 sm:text-left text-lg">
            START YOUR COMPLIANCE PROGRAM WITH A COMPLIMENTARY HORANGI
            CONSULTATION
          </h1>
          <h2 className="text-center text-sm p-5 sm:p-0 text-lg -mt-5 sm:mt-5 mb-64">
            Complete the form below to get in touch with our leading security
            consultants.
          </h2>
          <div id="hubspot-form" className="container mx-auto px-8 py-4 z-10 absolute max-w-4xl bg-white mt-128 sm:mt-96">
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_ID}
              formId={HUBSPOT_FORM_ID}
              onReady={handleHubspotFormReady}
              onSubmit={handleHubspotFormSubmit}
            />
            {renderContactFormHint()}
          </div>
        </section>

        <img
          src={whiteBottomRadius}
          alt=""
          className="relative w-full -mt-10 sm:-mt-1"
        />
        <section className="relative flex justify-center items-center mt-96 sm:mt-128 mb-10 sm:mb-64">
          <div className="container mx-auto px-4 md:px-8 z-20 relative mt-8 mb-32 md:mb-0">
            <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
              About Us
            </h3>
            <div className="mt-4 md:max-w-lg">
              <p className=" text-xl sm:text-2xl">
                Horangi Cyber Security is a Singapore-based CREST-accredited
                company backed by a team of global cyber security experts in
                Asia and trusted by Industry Leaders such as GOJEK, MoneySmart,
                PropertyGuru and Tiket.com.
              </p>
              <p className=" text-xl sm:text-2xl mt-10">
                Founded by ex-Palantir cybersecurity experts Paul Hadjy and Lee
                Sult in 2016, Horangi Cyber Security offers the latest in
                product innovation and technology to protect organizations.
              </p>
            </div>
            <div className="partner logo placeholder mx-auto mt-16 text-center md:text-left">
              <img
                className={style.clientLogo}
                src={clientLogo1}
                alt="PropertyGuru"
              />
              <img
                className={style.clientLogo}
                src={clientLogo2}
                alt="Tiket.com"
                style={{ padding: "2px 0" }}
              />
              <img
                className={style.clientLogo}
                src={clientLogo3}
                alt="Go Jek"
                style={{ padding: "3px 0" }}
              />
              <img
                className={style.clientLogo}
                src={clientLogo4}
                alt="MoneySmart"
                style={{ padding: "6px 0" }}
              />
            </div>
          </div>
          <Img
            className={style.mainTigerImage}
            fluid={props.data.mainTigerImage.childImageSharp.fluid}
            alt="Background"
          />
          <div
            className="absolute top-0 left-0 w-full h-full bg-black z-10"
            style={{ opacity: "0.7" }}
          />
        </section>
      </div>
    </DefaultLayout>
  );
}

export default React.memo(MasCyberHygiene);

export const QUERY = graphql`
  query MasQuery {
    mainTigerImage: file(relativePath: { eq: "main-tiger.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImage: file(
      relativePath: { eq: "background-products-item.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
